import React from 'react';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white min-h-screen flex flex-col">
    
      {/* Main content */}
      <div className="flex-grow flex flex-col items-center justify-center px-4 text-center">
        <div className="mb-6">
          <svg className='fill-current w-16 h-16' viewBox="0 0 99 99" xmlns="http://www.w3.org/2000/svg">
            <path filerule="evenodd" clipRule="evenodd" d="M49.5014 99C22.1756 98.9686 0.0314189 76.8263 0 49.5028C0.026686 22.18 22.1705 0.0330153 49.4986 0C76.8244 0.0314103 98.9686 22.1736 99 49.4971C98.9733 76.82 76.8295 98.967 49.5014 99L49.5014 99ZM45.5103 72.849H45.6928C47.2049 72.8546 48.6564 72.2551 49.7238 71.1842L77.9468 42.9637C79.0213 41.8959 79.623 40.442 79.6174 38.9273C79.6206 37.4164 79.0191 35.967 77.9468 34.9023C76.88 33.8299 75.4286 33.2284 73.9158 33.2319C72.4026 33.2265 70.9505 33.8282 69.8848 34.9023L45.5103 59.2689L33.2291 46.9944C32.1622 45.9219 30.7108 45.3205 29.198 45.3239C27.6832 45.319 26.2295 45.9205 25.1613 46.9944C22.9355 49.2207 22.9355 52.8295 25.1613 55.0558L41.2911 71.1842C42.3565 72.2548 43.8061 72.8543 45.3165 72.849L45.5103 72.849Z" fill="#25A750" />
          </svg>

        </div>
        <h1 className="text-2xl font-inter font-semibold mb-2">Thank you for submitting.</h1>
        <p className="text-black text-lg font-inter font-medium leading-5 mb-8">
          Please wait patiently for review. Once approved, you will get the treasure.
        </p>
      </div>

      {/* Back button */}
      <div className="px-4 pb-8">
        <button
          onClick={() => navigate('/home')}
          className="w-full bg-[#1388FF] text-white py-5 rounded-xl text-xl font-inter font-semibold"
        >
          back
        </button>
      </div>
    </div>
  );
};

export default ThankYou;