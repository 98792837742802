import { useCallback } from 'react';

const useWebAppNavigation = () => {
  const openLink = useCallback((url) => {
    // Implementation for opening a regular link
    window.open(url, '_blank');
  }, []);

  const openTelegramLink = useCallback((url) => {
    // Implementation for opening a Telegram link
    // This might involve using the Telegram Web App API if available
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openTelegramLink(url);
    } else {
      window.open(url, '_blank');
    }
  }, []);

  return { openLink, openTelegramLink };
};

export default useWebAppNavigation;