import React from 'react';

const LoadingButton = ({ 
  onClick, 
  isLoading, 
  children, 
  loadingText = "Loading...",
  backgroundColor = "#1388FF",
  hoverColor = "#1070D8",
  textColor = "white",
  className = "",
  disabledOpacity = "70",
  spinnerColor = "white",
  spinnerSize = "6",
  ...props
}) => {
  const baseClasses = `
    py-4 mx-5 rounded-xl text-xl font-inter font-semibold mb-3
    flex items-center justify-center transition-colors duration-300
  `;

  const buttonClasses = `
    ${baseClasses}
    ${className}
    ${isLoading ? `opacity-${disabledOpacity} cursor-not-allowed` : `hover:bg-[${hoverColor}]`}
    bg-[${backgroundColor}] text-${textColor}
  `;

  return (
    <button
      onClick={onClick}
      disabled={isLoading}
      className={buttonClasses}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
      }}
      {...props}
    >
      {isLoading ? (
        <>
          <div 
            className={`animate-spin rounded-full h-${spinnerSize} w-${spinnerSize} border-t-2 border-b-2 mr-3`}
            style={{ borderColor: spinnerColor }}
          ></div>
          {loadingText}
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingButton;