import { useQuery } from '@tanstack/react-query';
import walletServiceInstance from '../../services/wallet-service';
 
 function useGetWalletWithdrawConfig(data) {
  return useQuery({
    queryKey: [walletServiceInstance.queryKeys.walletWithdrawConfig + data?.token],
    queryFn: () => walletServiceInstance.walletWithdrawConfig(data),
  });
}

export default useGetWalletWithdrawConfig;