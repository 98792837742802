import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useGetTeamInfo from '../hooks/wallet/get-team-info';
import useGetTeamRewardsList from '../hooks/wallet/get-team-rewards-list';
import useTeamRewardReceive from '../hooks/wallet/team-receive-reward';
import { errorToast, successToast } from '../utils/toastMessage';
import ModalLoader from '../components/ModalLoader';

export default function Invitation() {
  // init
  const navigate = useNavigate();
  const token = localStorage?.getItem('token')
  const listRef = useRef(null);

  // state
  const [copySuccess, setCopySuccess] = useState(false);

  // api
  const { data: teamInfoData, isLoading: teamInfoDataIsLoading } = useGetTeamInfo({ token: token })
  const useGetTeamRewardsListInfinity = useGetTeamRewardsList({ token: token })
  const useTeamRewardReceiveMutation = useTeamRewardReceive()

  const invitationLink = teamInfoData?.data?.data?.invite_link ?? 'N/A';


  const handleScroll = useCallback(() => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 1) {
        useGetTeamRewardsListInfinity?.fetchNextPage();
      }
    }
    // eslint-disable-next-line 
  }, [useGetTeamRewardsListInfinity]);

  useEffect(() => {
    const currentListRef = listRef.current;
    if (currentListRef) {
      currentListRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentListRef) {
        currentListRef.removeEventListener('scroll', handleScroll);
      }
    };

  }, [handleScroll]);


  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(invitationLink);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset success message after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleReceiveReward = (id) => {
    const webApp = window?.Telegram?.WebApp
    const token = localStorage.getItem('token')
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel: 'tt',
      device_no: webApp?.BiometricManager.deviceId,
      token: token,
      record_id: id
    }
    useTeamRewardReceiveMutation.mutate(payload, {
      onSuccess: (data) => {
        if (data?.data?.error === 0) {

          successToast('Receive reward successfully')
        } else {
          errorToast('Something went wrong while receiving reward')
        }
      }
    })
  }

  return (
    <div className="flex flex-col h-screen bg-white">
      {/* Header - Kept static */}
      <div className="px-4 py-4 border-b border-gray-200 flex items-center relative">
        <button onClick={() => navigate(-1)} className="absolute left-4">
          <svg className='w-4 h-4 mr-6' viewBox="0 0 10 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.67449 0.40287C10.1085 0.924524 10.1085 1.72512 9.67449 2.24677L2.97934 9.62238L9.67449 16.998C9.92837 17.5273 9.83945 18.177 9.45521 18.6003C9.07097 19.0236 8.48115 19.1216 8.00071 18.8419L0.368233 10.5996C0.138634 10.3363 0.00721185 9.98747 0 9.62238C0.00288841 9.26171 0.135282 8.91698 0.368236 8.66355L7.91702 0.40287C8.14953 0.145009 8.46583 0 8.79576 0C9.12568 0 9.44198 0.14501 9.67449 0.40287V0.40287Z" fill="black" />
          </svg>
        </button>
        <h1 className="text-lg font-inter font-normal w-full text-center">INVITATION</h1>
      </div>

      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="px-4 py-2">
          {/* Refer & Earn Card - Kept static */}
          <div className="bg-black text-white px-4 py-6 rounded-lg mb-5">
            <h2 className="text-lg font-inter font-normal mb-4 text-center">REFER&EARN</h2>
            <p className="text-base font-inter font-normal text-balance">
              You need to recruit many employees. For every income your employees generate, you’ll earn a 10% management fee, and you’ll also earn a 2% management fee from their subordinates’ earnings. The more employees you have, the higher your daily income! Easily make $1,000 a month!
            </p>
          </div>

          {/* Invitation Link - Kept static */}
          <h2 className="text-lg font-inter font-normal mb-2">Invitation link</h2>
          <div className="flex mb-5">
            <input
              type="text"
              value={teamInfoData?.data?.data?.invite_link ?? 'N/A'}
              readOnly
              className="flex-grow text-[#B8BEC9] bg-gray-100 px-4 py-3 rounded-l-lg"
            />
            <button onClick={handleCopy} className="bg-[#1388FF] text-white px-4 py-2 rounded-r-lg">
              <p className="text-xl font-inter font-normal text-center">
                {copySuccess ? 'Copied!' : 'Copy'}
              </p>
            </button>
          </div>

          {/* Share Button - Kept static */}
          <a href={`https://t.me/share/url?url=${invitationLink}&text=${'Find the daily treasure codes, complete tasks, and easily earn USDT cash!'}`}
            className="block w-full bg-[#1388FF] text-xl font-inter font-normal text-white py-3 rounded-lg mb-5 text-center no-underline">
            Share with friends
          </a>

          {/* Invitation Record Header - Kept static */}
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-inter font-normal">INVITATION RECORD</h3>
            <span className="text-lg font-inter font-normal text-gray-500">{teamInfoData?.data?.data?.member_count ?? 'N/A'}</span>
          </div>
        </div>

        {/* Scrollable Invitation List */}
        <div ref={listRef} className="flex-grow overflow-y-auto px-4 pb-4">

          {(useGetTeamRewardsListInfinity.isLoading || useGetTeamRewardsListInfinity?.isPending || teamInfoDataIsLoading) ? (
            <div className="flex-grow flex items-center justify-center" style={{ minHeight: '100%' }}>
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            useGetTeamRewardsListInfinity?.data?.pages[0]?.data?.data?.records?.length > 0 ?
              useGetTeamRewardsListInfinity?.data?.pages?.map((data, index) => {
                return data?.data?.data?.records?.map((item, index) => {
                  return (
                    <div key={index} className="flex items-center justify-between mb-4">
                      <div className="flex items-center">
                        <img
                          src={item?.user?.avatar}
                          alt={`Avatar of ${item?.user?.nickname}`}
                          className="w-10 h-10 rounded-full mr-3 object-cover"
                        />
                        <div>
                          <p className="text-lg font-inter font-normal">{item?.user?.nickname}</p>
                          <p className={`text-xs font-inter font-normal ${item?.status === 'Completed' ? 'text-green-500' : 'text-green-600'}`}>
                            {item?.remarks ?? 'N/A'}
                          </p>
                        </div>
                      </div>
                      {item?.received !== 0 ? (
                        <div className="bg-green-500 h-10 w-20 flex justify-center items-center rounded-md">
                          <svg className='w-4 h-4 fill-current' viewBox="0 0 27 22" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.03512 14.3392L3.78904 9.02971L0 12.8601L9.03512 22L27 3.83221L23.2128 0L9.03512 14.3392Z" fill="white" />
                          </svg>
                        </div>
                      ) : (
                        <div onClick={() => handleReceiveReward(item?.id)} className="bg-[#1388FF] flex justify-center items-center text-center text-base font-inter font-normal text-white h-12 w-24 rounded-md uppercase py-2">
                          {item?.token ?? 'N/A'} {item?.symbol ?? 'N/A'}
                        </div>
                      )}
                    </div>

                  )
                })
              })
              : (
                <div className="flex-grow flex items-center justify-center" style={{ minHeight: '100%' }}>
                  <div className="text-black text-lg font-inter font-bold">No Record Found</div>
                </div>
              )
          )}

          {(useGetTeamRewardsListInfinity?.isFetchingNextPage) && (
            <div className="flex-grow flex items-center justify-center h-24">
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
            </div>)}
        </div>
        <ModalLoader isLoading={useTeamRewardReceiveMutation?.isPending} />

      </div>
    </div>
  )
}
