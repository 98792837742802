import { fetcher } from "../utils/fetcher";

class TaskService {
  queryKeys = {
    userLogin: 'userLogin',
    receiveMissionRewards: 'receiveMissionRewards',
    getTaskList: 'getTaskList',
    getUploadImageUrl : 'getUploadImageUrl',
    submitTask : 'submitTask',
    getCompletedTaskList : 'getCompletedTaskList'
  };

  getTaskList = async (data) => {
    const webApp = window?.Telegram?.WebApp
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel: 'tt',
      device_no: webApp?.BiometricManager.deviceId,
      token: data?.token,
      page: data?.pageParam,
      size: 10
    }

    return fetcher({
      url: `/task/list`,
      method: 'POST',
      data: payload
    });
  }

  getCompletedTaskList = async (data) => {
    const webApp = window?.Telegram?.WebApp
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel: 'tt',
      device_no: webApp?.BiometricManager.deviceId,
      token: data?.token,
      page: data?.pageParam,
      size: 10
    }

    return fetcher({
      url: `/task/completed_list`,
      method: 'POST',
      data: payload
    });
  }

  receiveMissionRewards = async (data) => {

    return fetcher({
      url: `/task/receive`,
      method: 'POST',
      data
    });
  }


  getUploadImageUrl = async (data) => {

    return fetcher({
      url: `/utils/upload_config`,
      method: 'POST',
      data
    });
  }

  submitTask = async (data) => {

    return fetcher({
      url: `/task/submit`,
      method: 'POST',
      data
    });
  }
  

}



const taskServiceInstance = new TaskService();
export default taskServiceInstance;