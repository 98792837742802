import { useQuery } from '@tanstack/react-query';
import walletServiceInstance from '../../services/wallet-service';

 
 function useGetWalletInfo(data) {
  return useQuery({
    queryKey: [walletServiceInstance.queryKeys.walletInfo + data?.token],
    queryFn: () => walletServiceInstance.walletInfo(data),
  });
}

export default useGetWalletInfo;