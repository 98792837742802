import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import SubmitTaskModal from '../components/SubmitTaskModal';
import CongratulationsModal from '../components/CongratulationsModal';
import useReceiveMissionReward from '../hooks/task/receive-mission-reward';
import LoadingButton from '../components/LoadingButton';
import { errorToast, successToast } from '../utils/toastMessage';
import useWebAppNavigation from '../hooks/useWebAppNavigation';

export default function Exchange() {
  // init
  const navigate = useNavigate();
  const location = useLocation();
  const { openLink, openTelegramLink } = useWebAppNavigation()
  const { task } = location?.state


  // state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalReceiveOpen, setIsModalReceiveOpen] = useState(false);

  // api
  const useReceiveMissionRewardMutation = useReceiveMissionReward()

  const openReceiveModal = () => setIsModalReceiveOpen(true);
  const closeReceiveModal = () => {
    setIsModalReceiveOpen(false)
    navigate(-1)
  };

  const handleReceive = () => {
    // Handle receive logic here
    closeReceiveModal();
    // Navigate or perform other actions as needed
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleClaimReward = () => {
    const webApp = window?.Telegram?.WebApp
    const token = localStorage.getItem('token')
    console.log({ token });
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel: 'tt',
      device_no: webApp?.BiometricManager.deviceId,
      token: token,
      task_id: task?.id
    }

    useReceiveMissionRewardMutation.mutate(payload, {
      onSuccess: (data) => {
        console.log(data?.data, 'celm');
        if (data?.data?.error === 0) {
          successToast('Claim successfully')
          openReceiveModal()
        } else {
          errorToast(`${data?.data?.message}`)
        }
      }
    })

  }

  const handleGo = () => {
    if (task?.link.indexOf('https://t.me/') < 0) {
      openLink(task?.link)
    } else {
      console.log(task?.link)
      openTelegramLink(task?.link)
    }

    // window.location.href = task?.link; // Redirect to the link

  }

  return (
    <div className=" bg-black min-h-screen w-full mx-auto flex flex-col text-white overflow-y-auto relative">
      {/* Header */}
      <header className="p-4 bg-white border-b-2 rounded-b-3xl sticky top-0 z-40">
        <div onClick={() => navigate(-1)} className='mt-2'>
          <svg className='w-4 h-4 mr-6' viewBox="0 0 10 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.67449 0.40287C10.1085 0.924524 10.1085 1.72512 9.67449 2.24677L2.97934 9.62238L9.67449 16.998C9.92837 17.5273 9.83945 18.177 9.45521 18.6003C9.07097 19.0236 8.48115 19.1216 8.00071 18.8419L0.368233 10.5996C0.138634 10.3363 0.00721185 9.98747 0 9.62238C0.00288841 9.26171 0.135282 8.91698 0.368236 8.66355L7.91702 0.40287C8.14953 0.145009 8.46583 0 8.79576 0C9.12568 0 9.44198 0.14501 9.67449 0.40287V0.40287Z" fill="black" />
          </svg>
        </div>
        <div className="flex-1 text-black text-center font-inter font-semibold text-4xl mt-4">
          {/* Exchange 10<br />to USDT */}
          {task?.name ?? 'N/A'}
        </div>
        <p className="text-center text-lg font-inter font-medium text-black mt-6 mb-4 leading-5">
          {/* Use your PFT to convert 10USDT and get 50 USDT for completing the task */}
          {task?.content ?? 'N/A'}
        </p>
      </header>

      {/* Main content */}
      <main className="flex-1 flex flex-col space-y-4 z-0">
        {/* Dark section with rounded edges */}
        <div className="bg-[#141415] p-4 border-b-2 border-b-white rounded-2xl flex justify-between items-center">
          <span className="text-white text-sm font-inter font-semibold">Condition</span>
          <span className="text-white text-lg font-inter font-semibold"> {task?.target ?? 'N/A'} {task?.uint ?? 'N/A'}</span>
        </div>

        {/* Amount to get */}
        <div className="flex flex-col justify-center items-center py-11">
          <p className="text-white text-lg font-inter font-semibold mb-4">You will get</p>
          <p className="text-5xl font-inter font-bold">{task?.amount ?? 'N/A'}<span className="text-base font-inter font-bold ml-1">{task?.symbol ?? 'N/A'}</span></p>
        </div>

        {/* Action buttons */}
        <LoadingButton onClick={handleGo}>
          Go
        </LoadingButton>
        {task?.finished && (<LoadingButton
          onClick={handleClaimReward}
          isLoading={useReceiveMissionRewardMutation?.isPending}
          loadingText="Claim the treasure"
          backgroundColor="#ffffff"
          hoverColor="#ffffff"
          textColor="black"
          spinnerColor="black"
          spinnerSize="5"
        >
          Claim the treasure
        </LoadingButton>)}
        <LoadingButton
          onClick={openModal}
          isLoading={false}
          loadingText="Submit proof"
          backgroundColor="#ffffff"
          hoverColor="#ffffff"
          textColor="black"
          spinnerColor="black"
          spinnerSize="5"
        >
          Submit proof
        </LoadingButton>
      </main>

      <div>
        <SubmitTaskModal isOpen={isModalOpen} onClose={closeModal} taskId={task?.id} voucher_required={task?.voucher_required} />
        <CongratulationsModal isOpen={isModalReceiveOpen} onClose={closeReceiveModal} onReceive={handleReceive} amount={task?.amount ?? 'N/A'} symbol={task?.symbol ?? 'N/A'} />
      </div>

    </div>
  )
}
