import { useMutation } from '@tanstack/react-query';

import taskServiceInstance from '../../services/task-service';
import { errorToast } from '../../utils/toastMessage';

function useReceiveMissionReward() {

  return useMutation({
    mutationFn: taskServiceInstance.receiveMissionRewards,
    onError: (error) => {
      console.log(error, 'mission reward ERR');
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useReceiveMissionReward;