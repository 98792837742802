import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './screens/Home'
import Withdraw from './screens/Withdraw'
import Exchange from './screens/Exchange'
import ThankYou from './screens/ThankYou';
import Login from './screens/Login';
import WithdrawalRecord from './screens/WithdrawalRecords';
import CompletedTasks from './screens/CompletedTasks';
import Invitation from './screens/Invitation';

const App = () => {

  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/exchange" element={<Exchange />} />
        <Route path="/withdarwalrecords" element={<WithdrawalRecord />} />
        <Route path="/completedtasks" element={<CompletedTasks />} />
        <Route path="/invitation" element={<Invitation />} />
        <Route path="/thankyou" element={<ThankYou />} />

      </Routes>
    </BrowserRouter>
  )
}

export default App