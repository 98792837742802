import React from 'react'

export default function WithdrawalRecordCard({item, index}) {

  const date = new Date(item?.create_at * 1000);

  // Format the date as YYYY/MM/DD HH:MM:SS
  const formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')
    }/${String(date.getDate()).padStart(2, '0')
    } ${String(date.getHours()).padStart(2, '0')
    }:${String(date.getMinutes()).padStart(2, '0')
    }:${String(date.getSeconds()).padStart(2, '0')
    }`;
  return (
    <div className="border-b border-gray-200 py-4 px-4">
      <div className="flex justify-between items-start mb-2">
        <div className="text-xs font-inter font-medium text-gray-500 w-3/4 pr-2 break-all line-clamp-2">
          ADDRESS: {item?.address ?? 'N/A'}
        </div>
        <div className="text-lg font-inter font-bold text-right">
          {item?.amount ?? 'N/A'}<span className="text-xs font-inter font-medium ml-1">{item?.symbol?.toUpperCase()}</span>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-center">
          <span className={`text-xs font-inter font-medium ${item?.status === 3 ? 'text-red-500' :
            item?.status === 2 ? 'text-green-500' : item?.status === 1 ? 'text-[#facc15]' :
              'text-blue-500'
            }`}>
            status: {item?.status === 0 ? 'In-Review' : item?.status === 1 ? 'Pending' : item?.status === 2 ? 'Success' : 'Failed'}
          </span>
          <span className="text-xs font-inter font-medium text-gray-500">{formattedDate}</span>
        </div>
        {/* {tips && (
        <p className="text-xs text-blue-500 line-clamp-2">{tips}</p>
      )} */}
      </div>
    </div>
  )
}
