import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useGetUploadImageUrl from '../hooks/task/get-upload-image-url';
import ModalLoader from './ModalLoader';
import { errorToast, successToast } from '../utils/toastMessage';
import useSubmitTask from '../hooks/task/submit-task';

const SubmitTaskModal = ({ isOpen, onClose, taskId , voucher_required}) => {
  // init
  const navigate = useNavigate();
  
  // state
  const [text, setText] = useState('');
  const [image, setImage] = useState(null);
  const [textError, setTextError] = useState('');
  const [imageError, setImageError] = useState('');
  const [binaryData, setBinaryData] = useState(null);
  const [imageUplaodProccessing, setImageUplaodProccessing] = useState(false)

  // api
  const useGetUploadImageUrlMutation = useGetUploadImageUrl()
  const useSubmitTaskMutation = useSubmitTask()

  const validateInputs = () => {
    let isValid = true;
    if (!text.trim()) {
      setTextError('Please enter a description');
      isValid = false;
    }
    if (!image) {
      setImageError('Please select an image');
      isValid = false;
    }
    return isValid;
  };

  const validateInputsDes = () => {
    let isValid = true;
    if (!text.trim()) {
      setTextError('Please enter a description');
      isValid = false;
    }
    return isValid;
  };

  const handleTextChange = (e) => {
    setText(e.target.value)
    setTextError('');
  };


  const handleImageChange = (e) => {

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Create a URL for the image
      setImage(URL.createObjectURL(file));

      // Read the file as binary data
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        setBinaryData(uint8Array);
      };

      // Read the file as an ArrayBuffer
      reader.readAsArrayBuffer(file);

      setImageError('');
    }
  };

  const uploadImageToCloudflareBucket = async (url, obj_url, binaryData) => {
    const token = localStorage.getItem('token')
    try {
      // Parse the URL
      const parsedUrl = new URL(url);
      const host = parsedUrl.hostname;
      console.log(binaryData.length.toString());

      // Set up headers
      const headers = {
        'Content-Type': 'image/jpeg',
        'Pragma': 'no-cache',
        'Accept': 'application/json, text/plain, */*',
        'Sec-Fetch-Site': 'cross-site',
        'Accept-Language': 'zh-CN,zh-Hans;q=0.9',
        'Cache-Control': 'no-cache',
        'Sec-Fetch-Mode': 'cors',
        'Accept-Encoding': 'gzip, deflate, br',
        'Origin': 'https://fish-jump-share.pages.dev',
        'Content-Length': '44008',
        'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko)',
        'Referer': 'https://fish-jump-share.pages.dev/',
        'Connection': 'keep-alive',
        'Host': host,
        'Sec-Fetch-Dest': 'empty'
      };

      // Make the fetch request
      const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: binaryData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Check if the response is JSON
      const payload = {
        token: token,
        task_id: taskId,
        voucher: [obj_url],
        remarks: text
      }
      // console.log(response.success);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        useSubmitTaskMutation?.mutate(payload, {
          onSuccess: (data) => {
            if (data?.data?.error === 2006 || data?.data?.error === 0) {
              successToast('Submitted successfully')
              navigate('/thankyou')
            } else {
              errorToast('Something went wrong while Submitted')
            }
            onClose()
          }
        })
        setImageUplaodProccessing(false)
        return data;
      } else {
        const text = await response.text();

        useSubmitTaskMutation?.mutate(payload, {
          onSuccess: (data) => {
            if (data?.data?.error === 2006 || data?.data?.error === 0) {
              successToast('Submitted successfully')
              navigate('/thankyou')
            } else {
              errorToast('Something went wrong while Submitted')
            }
            onClose()
          }
        })
        setImageUplaodProccessing(false)
        // successToast('Upload successfully')
        return text;
      }

    } catch (error) {
      setImageUplaodProccessing(false)
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleSubmit = () => {

    if ( voucher_required !== 0 ? !validateInputs() : !validateInputsDes() ) {
      return;
    }
    const webApp = window?.Telegram?.WebApp
    const token = localStorage.getItem('token')
    if (voucher_required !== 0) {
      const payload = {
        platform: webApp?.platform,
        version: webApp?.version,
        ts: Date.now(),
        channel: 'tt',
        device_no: webApp?.BiometricManager.deviceId,
        token: token,
        mimetype: 'image/png'
      }
      useGetUploadImageUrlMutation.mutate(payload, {
        onSuccess: (data) => {
          setImageUplaodProccessing(true)
          console.log(data?.data?.data?.obj_url);
  
          uploadImageToCloudflareBucket(data?.data?.data?.upload_url, data?.data?.data?.obj_url, binaryData,);
        }
      })
    } else {
      const payload = {
        token: token,
        task_id: taskId,
        voucher: [],
        remarks: text
      }
      useSubmitTaskMutation?.mutate(payload, {
        onSuccess: (data) => {
          if (data?.data?.error === 2006 || data?.data?.error === 0) {
            successToast('Submitted successfully')
            navigate('/thankyou')
          } else {
            errorToast('Something went wrong while Submitted')
          }
          onClose()
        }
      })
    }
   


  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
      <div className="bg-white rounded-2xl w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="p-6 pt-9">

          <h2 className="text-xl text-black font-semibold text-center mb-6">
            Please submit the text and screenshot of the completed task
          </h2>
          <input
            type="text"
            placeholder="Please enter"
            value={text}
            onChange={handleTextChange}
            className="w-full text-black p-3 border border-gray-300 rounded-lg mb-2"
          />
          {textError && <p className="text-red-500 text-sm mt-1 mb-4">{textError}</p>}
         {  voucher_required !== 0 && <div className="w-full mb-6 flex flex-col justify-center items-center">
            <label htmlFor="image-upload" className="block">
              {image ? (
                <img src={image} alt="Selected" className="w-20 h-32 object-cover rounded-lg flex items-center justify-center" />
              ) : (
                <div className="w-20 h-24 border-2 border-gray-300 rounded-lg flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-12 h-12 text-gray-400">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                </div>
              )}
            </label>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
            />
            {imageError && <p className="text-red-500 text-sm mt-1">{imageError}</p>}
          </div>}
          <button
            onClick={handleSubmit}
            className="w-full bg-[#1388FF] text-white py-5 rounded-2xl text-lg font-semibold"
          >
            Submit
          </button>
        </div>
      </div>

      <ModalLoader isLoading={useGetUploadImageUrlMutation?.isPending || imageUplaodProccessing || useSubmitTaskMutation?.isPending} />
    </div>
  );
};

export default SubmitTaskModal;