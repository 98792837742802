import { useMutation } from '@tanstack/react-query';
import walletServiceInstance from '../../services/wallet-service';
import { errorToast } from '../../utils/toastMessage';


function useBindWalletAddress() {

  return useMutation({
    mutationFn: walletServiceInstance.bindWalletAddress,
    onError: (error) => {
      console.log(error, 'bind address ERR');
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useBindWalletAddress;