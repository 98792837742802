import Axios from "axios";


export const fetcher = async (config) => {
  const { url, method, data, headers } = config;


  return await Axios.request({
    baseURL: process.env.REACT_APP_API,
    url,
    method: method ?? 'GET',
    data,
    ...config,
    headers: {
      // Authorization: !!access_token ? access_token : undefined,
      ...config?.headers,
      ...headers,
    },
    // withCredentials: true,
  });
};