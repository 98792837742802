import { useQuery } from '@tanstack/react-query';
import walletServiceInstance from '../../services/wallet-service';
 
 function useGetTeamInfo(data) {
  return useQuery({
    queryKey: [walletServiceInstance.queryKeys.getTeamInfo + data?.token],
    queryFn: () => walletServiceInstance.getTeamInfo(data),
  });
}

export default useGetTeamInfo;