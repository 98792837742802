import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import authServiceInstance from '../../services/auth-service';
import { saveSession } from '../../utils/helperFunctions';
import { errorToast } from '../../utils/toastMessage';

// import { errorToast, successToast } from '../../utils/toastMassage';

function useUserLogin() {

  const navigate = useNavigate()

  return useMutation({
    mutationFn: authServiceInstance.userLogin,
    onSuccess: (data) => {
      console.log(data?.data);
      console.log(data?.data?.data?.token, 'login time',data?.data?.data?.expire_at);
      // successToast('Login Successfully')
      if (data?.data?.error === 0) {
        setTimeout(() => {
          saveSession(data?.data?.data?.token, data?.data?.data?.expire_at)
          navigate('/home');
        }, 100);
      } else {
        errorToast('Something went wrong while login')
      }

    },
    onError: (error) => {
      console.log(error, 'LOGIN ERR');
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useUserLogin;