import React from 'react';

const CongratulationsModal = ({ isOpen, onClose, onReceive,amount,symbol }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl w-11/12 max-w-sm">
        <div className="px-6 py-9 space-y-7">
          <h2 className="text-center text-lg font-semibold text-black">
            Congratulations, you got
          </h2>
          <p className="text-center text-black">
            <span className="text-5xl font-bold">{amount}</span>
            <span className="text-2xl ml-1">{symbol}</span>
          </p>
          <button
            onClick={onClose}
            className="w-full bg-[#1388FF] text-white py-5 rounded-xl text-xl font-semibold"
          >
            receive
          </button>
        </div>
      </div>
    </div>
  );
};

export default CongratulationsModal;