import { useMutation } from '@tanstack/react-query';
import walletServiceInstance from '../../services/wallet-service';
import { errorToast } from '../../utils/toastMessage';


function useTeamRewardReceive() {

  return useMutation({
    mutationFn: walletServiceInstance.teamRewardReceive,
    onError: (error) => {
      console.log(error, 'team reward  ERR');
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useTeamRewardReceive;