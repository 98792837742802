import React, { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import useGetCompletedTaskList from '../hooks/task/get-completed-tasks';

const CompletedTasks = () => {
  // init
  const navigate = useNavigate();
  const listRef = useRef(null);
  const token = localStorage?.getItem('token')

  // api
  const useGetCompletedTaskListInfinity = useGetCompletedTaskList({ token: token })

  const handleScroll = useCallback(() => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 1) {
        useGetCompletedTaskListInfinity.fetchNextPage()
      }
    }
  }, [useGetCompletedTaskListInfinity]);

  useEffect(() => {
    const currentListRef = listRef.current;
    if (currentListRef) {
      currentListRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentListRef) {
        currentListRef.removeEventListener('scroll', handleScroll);
      }
    };

  }, [handleScroll]);

  return (
    <div className="flex flex-col h-screen bg-white">
      {/* Header */}
      <div className="px-4 py-4 border-b border-gray-200 flex items-center relative">
        <button onClick={() => navigate(-1)} className="absolute left-4">
          <svg className='w-4 h-4 mr-6' viewBox="0 0 10 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.67449 0.40287C10.1085 0.924524 10.1085 1.72512 9.67449 2.24677L2.97934 9.62238L9.67449 16.998C9.92837 17.5273 9.83945 18.177 9.45521 18.6003C9.07097 19.0236 8.48115 19.1216 8.00071 18.8419L0.368233 10.5996C0.138634 10.3363 0.00721185 9.98747 0 9.62238C0.00288841 9.26171 0.135282 8.91698 0.368236 8.66355L7.91702 0.40287C8.14953 0.145009 8.46583 0 8.79576 0C9.12568 0 9.44198 0.14501 9.67449 0.40287V0.40287Z" fill="black" />
          </svg>
        </button>
        <h1 className="text-lg font-inter font-normal w-full text-center">Completed Tasks</h1>
      </div>

      {/* Scrollable list */}
      <div ref={listRef} className="flex-grow overflow-y-auto pt-3">
        {(useGetCompletedTaskListInfinity.isLoading || useGetCompletedTaskListInfinity?.isPending) ? (
          <div className="flex-grow flex items-center justify-center" style={{ minHeight: '100%' }}>
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          useGetCompletedTaskListInfinity?.data?.pages[0]?.data?.data?.list?.length > 0 ?
            useGetCompletedTaskListInfinity?.data?.pages?.map((data, index) => {
              return data?.data?.data?.list?.map((item, index) => {
                return (
                  <div key={index} className="bg-[#F5F8FB] px-3 py-3 flex items-center border-b border-gray-100 mx-4 mb-3 rounded-xl">
                    <div className="w-14 h-14 rounded-2xl mr-2 sm:mr-4 flex-shrink-0 flex items-center justify-center overflow-hidden">
                      <img
                        src={item?.icon}
                        alt="Task icon"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex-grow min-w-0">
                      <h2 className="text-base text-black font-medium font-inter">{item?.name}</h2>
                      <p className="text-xs text-[#7F858F] font-medium font-inter line-clamp-2">{item?.content}</p>
                    </div>
                    <div className="text-right flex-shrink-0">
                      <span className="text-lg font-bold">{item?.amount}</span>
                      <span className="text-sm ml-1 uppercase">{item?.symbol}</span>
                    </div>
                  </div>

                )
              })
            })
            : (
              <div className="flex-grow flex items-center justify-center" style={{ minHeight: '100%' }}>
                <div className="text-black text-lg font-inter font-bold">No Task Found</div>
              </div>
            )
        )}

        {(useGetCompletedTaskListInfinity?.isFetchingNextPage) && (
          <div className="flex-grow flex items-center justify-center h-24">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
          </div>)}
      </div>
    </div>
  );
};

export default CompletedTasks;