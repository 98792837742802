import { useMutation } from '@tanstack/react-query';

import { errorToast } from '../../utils/toastMessage';
import taskServiceInstance from '../../services/task-service';


function useSubmitTask() {

  return useMutation({
    mutationFn: taskServiceInstance.submitTask,
    onError: (error) => {
      console.log(error, 'bind address ERR');
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useSubmitTask;