import { useMutation } from '@tanstack/react-query';
import taskServiceInstance from '../../services/task-service';
import { errorToast } from '../../utils/toastMessage';


function useGetUploadImageUrl() {

  return useMutation({
    mutationFn: taskServiceInstance.getUploadImageUrl,
    onError: (error) => {
      console.log(error, 'get upload url ERR');
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useGetUploadImageUrl;