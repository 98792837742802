import axios from 'axios';

export const saveSession = (token, expireAt) => {
  const expirationTime = Date.now(expireAt * 1000) ;
  localStorage.setItem('token', token);
  localStorage.setItem('expire_at', expirationTime);
};

export const isSessionValid = () => {
  const token = localStorage.getItem('token');
  const expireAt = localStorage.getItem('expire_at');

  if (!token || !expireAt) {
    return false;
  }
console.log('token check',expireAt > Date.now() , Date.now() ,expireAt,'TOKEN',token);
  return expireAt > Date.now() ;
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expire_at');

};

export async function getHeaders() {
  let userData = localStorage.getItem('token');
  if (userData) {
    userData = JSON.parse(userData);
    //console.log(userData.accessToken, 'header')
    return {
      authorization: `Bearer ${userData.token}`,
    };
  }
  return {};
}


export async function apiReq(
  endPoint,
  data,
  method,
  headers,
  requestOptions = {}
) {

  return new Promise(async (res, rej) => {

    headers = {
      // ...getTokenHeader,
      ...headers
    };

    if (method === 'get' || method === 'delete') {
      data = {
        ...requestOptions,
        ...data,
        headers
      };
    }
    console.log("endPointendPoint", endPoint)

    axios[method](endPoint, data, { headers })
      .then(result => {
        // console.log("api result response",result)
        const { data } = result;

        if (data.status === false) {
          return rej(data);
        }

        return res(data);
      })
      .catch(error => {
        console.log(error)
        console.log(error && error.response, 'the error respne')
        if (error && error.response && error.response.status === 401) {
          //logout user
          alert("user not valid")
        }
        if (error && error.response && error.response.data) {
          if (!error.response.data.message) {
            return rej({ ...error.response.data, message: error.response.data.message || "Network Error" })
          }
          return rej(error.response.data)
        } else {
          return rej({ message: "Network Error"});
        }
      });
  });
}

export function apiPost(endPoint, data, headers = {}) {
  return apiReq(endPoint, data, 'post', headers);
}

export function apiDelete(endPoint, data, headers = {}) {
  return apiReq(endPoint, data, 'delete', headers);
}

export function apiGet(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, 'get', headers, requestOptions);
}

export function apiPut(endPoint, data, headers = {}) {
  return apiReq(endPoint, data, 'put', headers);
}
