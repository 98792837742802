import { useInfiniteQuery } from '@tanstack/react-query';
import taskServiceInstance from '../../services/task-service';

function useGetTaskList(data) {

  return useInfiniteQuery({
    queryKey: [taskServiceInstance.queryKeys.getTaskList + data?.token],
    queryFn: ({ pageParam = 1 }) => taskServiceInstance.getTaskList({
      pageParam: pageParam ?? 1,
      ...data,
    }),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.data?.current_page < lastPage?.data?.data?.total_page) {
        const currentPage = Number(lastPage?.data?.data?.current_page);
        const nextPage = isNaN(currentPage) ? 1 : currentPage + 1;
        return nextPage;
      }
      // console.log(lastPage?.data?.data?.current_page,'recall');
      // return 1;
    },
    initialPageParam: 1,
    enabled: !!data?.token,
  })

}

export default useGetTaskList;