import { useMutation } from '@tanstack/react-query';

import authServiceInstance from '../../services/auth-service';

// import { errorToast, successToast } from '../../utils/toastMassage';

function useUserConfig() {

  return useMutation({
    mutationFn: authServiceInstance.userConfig,
    onError: (error) => {
      console.log(error, 'Config ERR');
      // errorToast('Something went wrong, please try again later')

    },
  });
}

export default useUserConfig;