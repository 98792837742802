import React, { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

import useGetWithdrawalRecords from '../hooks/wallet/get-withdrawal-records';
import WithdrawalRecordCard from '../components/WithdrawalRecordCard';

// const WithdrawalRecordCard = (item, index) => {
//   const date = new Date(item?.item?.create_at * 1000);

//   // Format the date as YYYY/MM/DD HH:MM:SS
//   const formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')
//     }/${String(date.getDate()).padStart(2, '0')
//     } ${String(date.getHours()).padStart(2, '0')
//     }:${String(date.getMinutes()).padStart(2, '0')
//     }:${String(date.getSeconds()).padStart(2, '0')
//     }`;
//   return (
//     <div className="border-b border-gray-200 py-4">
//       <div className="flex justify-between items-start mb-2">
//         <div className="text-xs font-inter font-medium text-gray-500 w-3/4 pr-2 break-all line-clamp-2">
//           ADDRESS: {item?.item?.address ?? 'N/A'}
//         </div>
//         <div className="text-lg font-inter font-bold text-right">
//           {item?.item?.amount ?? 'N/A'}<span className="text-xs font-inter font-medium ml-1">{item?.item?.symbol?.toUpperCase()}</span>
//         </div>
//       </div>
//       <div className="flex flex-col space-y-2">
//         <div className="flex justify-between items-center">
//           <span className={`text-xs font-inter font-medium ${item?.item?.status === 3 ? 'text-red-500' :
//             item?.item?.status === 2 ? 'text-green-500' : item?.item?.status === 1 ? 'text-[#facc15]' :
//               'text-blue-500'
//             }`}>
//             status: {item?.item?.status === 0 ? 'In-Review' : item?.item?.status === 1 ? 'Pending' : item?.item?.status === 2 ? 'Success' : 'Failed'}
//           </span>
//           <span className="text-xs font-inter font-medium text-gray-500">{formattedDate}</span>
//         </div>
//         {/* {tips && (
//         <p className="text-xs text-blue-500 line-clamp-2">{tips}</p>
//       )} */}
//       </div>
//     </div>
//   )
// }

const WithdrawalRecord = () => {

  // init
  const navigate = useNavigate();
  const listRef = useRef(null);
  const token = localStorage?.getItem('token')

  // api
  const useGetWithdrawalRecordsInfinity = useGetWithdrawalRecords({ token: token })

  const handleScroll = useCallback(() => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 1) {
        useGetWithdrawalRecordsInfinity.fetchNextPage()
      }
    }
  }, [useGetWithdrawalRecordsInfinity]);

  useEffect(() => {
    const currentListRef = listRef.current;
    if (currentListRef) {
      currentListRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentListRef) {
        currentListRef.removeEventListener('scroll', handleScroll);
      }
    };

  }, [handleScroll]);


  return (
    <div className="flex flex-col h-screen bg-white">
      {/* Header */}
      <div className="px-4 py-4 border-b border-gray-200 flex items-center relative">
        <button onClick={() => navigate(-1)} className="absolute left-4">
          <svg className='w-4 h-4 mr-6' viewBox="0 0 10 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.67449 0.40287C10.1085 0.924524 10.1085 1.72512 9.67449 2.24677L2.97934 9.62238L9.67449 16.998C9.92837 17.5273 9.83945 18.177 9.45521 18.6003C9.07097 19.0236 8.48115 19.1216 8.00071 18.8419L0.368233 10.5996C0.138634 10.3363 0.00721185 9.98747 0 9.62238C0.00288841 9.26171 0.135282 8.91698 0.368236 8.66355L7.91702 0.40287C8.14953 0.145009 8.46583 0 8.79576 0C9.12568 0 9.44198 0.14501 9.67449 0.40287V0.40287Z" fill="black" />
          </svg>
        </button>
        <h1 className="text-lg font-inter font-normal w-full text-center">Withdrawal Record</h1>
      </div>

      {/* Scrollable list */}
      <div ref={listRef} className="flex-grow overflow-y-auto pt-3">
        {(useGetWithdrawalRecordsInfinity.isLoading || useGetWithdrawalRecordsInfinity?.isPending) ? (
          <div className="flex-grow flex items-center justify-center" style={{ minHeight: '100%' }}>
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          useGetWithdrawalRecordsInfinity?.data?.pages[0]?.data?.data?.records?.length > 0 ?
            useGetWithdrawalRecordsInfinity?.data?.pages?.map((data, index) => {
              return data?.data?.data?.records?.map((item, index) => {
                return (
                  <WithdrawalRecordCard key={index} item={item} />
                )
              })
            })
            : (
              <div className="flex-grow flex items-center justify-center" style={{ minHeight: '100%' }}>
                <div className="text-black text-lg font-inter font-bold">No Record Found</div>
              </div>
            )
        )}
        {(useGetWithdrawalRecordsInfinity?.isFetchingNextPage) && (
          <div className="flex-grow flex items-center justify-center h-24">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
          </div>)}
      </div>
    </div>
  );
};

export default WithdrawalRecord;