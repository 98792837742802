import { useMutation } from '@tanstack/react-query';
import walletServiceInstance from '../../services/wallet-service';
import { errorToast } from '../../utils/toastMessage';


function useWalletWithdrawAmount() {

  return useMutation({
    mutationFn: walletServiceInstance.walletWithdrawAmount,
    onError: (error) => {
      console.log(error, 'amount withdraw ERR');
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useWalletWithdrawAmount;